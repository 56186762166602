const constants = {
  url: process.env.VUE_APP_url,
  s3Bucket: process.env.VUE_APP_s3Bucket,
  loginServiceUrl: process.env.VUE_APP_loginServiceUrl,
  resumeParserUrl: process.env.VUE_APP_resumeParserUrl,
  apiUrl: process.env.VUE_APP_apiUrl,
  feedServiceUrl: process.env.VUE_APP_feedServiceUrl,
  orgId: "alfred",
  orgName: "alfred",
  libraryUrl:  process.env.VUE_APP_libraryUrl
};

export default constants;
