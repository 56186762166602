export default {
  OPEN_ADD_SKILL_POPUP(state, value) {
    state.addSkillPopup = value;
  },
  OPEN_CHAT_BOT_POPUP(state, value) {
    state.chatBotPopup = value;
  },
  OPEN_DISTRIBUTE_LIBRARY_POPUP(state, value) {
    state.distributeItemPopup = value;
  },
  OPEN_VERIFY_BOT_POPUP(state, value) {
    state.verifyPopup = value;
  },
  OPEN_MODIFY_ORG_POPUP(state, value) {
    state.modifyOrgPopup = value;
  },
  
};
