import state from './moduleResumeParserState'
import mutations from './moduleResumeParserMutations'
import actions from './moduleResumeParserActions'
import getters from './moduleResumeParserGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
