import state from './moduleModalsState'
import mutations from './moduleModalsMutations'
import actions from './moduleModalsActions'
import getters from './moduleModalsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
