export default {
  SET_PARSE_RESUME(state, value) {
    state.resume = value;
  },
  SET_IS_PARSED_SUCCESSFUL(state, value) {
    state.isParsedSuccessful = value;
  },
};

 
